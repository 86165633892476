import React from "react";

const Patient = ({ details }) => {
  if (!details) {
    return <p>No patient details available.</p>;
  }

  return (
    <div>
      <h1>Patient Details</h1>
      <p><strong>ID:</strong> {details.id}</p>
      <p><strong>Name:</strong> {details.name}</p>
      <p><strong>Age:</strong> {details.age}</p>
    </div>
  );
};

export default Patient;
