import React from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion'; // Import framer-motion
import './Home.css'; // Import the CSS for Home

// Import images
import slider1 from '../assets/slider1.avif';
import slider2 from '../assets/slider2.avif';
import slider3 from '../assets/slider3.avif';

const Home = () => {
  const sliderImages = [slider1, slider2, slider3];

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Arrows are now disabled
  };

  return (
    <div className="home-container">
      <Slider {...settings} className="image-slider">
        {sliderImages.map((image, index) => (
          <div key={index}>
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }} // Image starts small and faded
              animate={{ opacity: 1, scale: 1 }}   // Image animates to full size
              exit={{ opacity: 0, scale: 1.1 }}    // Slight zoom-out effect when exiting
              transition={{
                duration: 1,
                ease: 'easeInOut',
                delay: 0.2,
              }}
              className="slider-image-container"
            >
              <img
                src={image}
                alt={`Slider Image ${index + 1}`}
                className="slider-image"
              />
            </motion.div>
          </div>
        ))}
      </Slider>
      <h1>Welcome to the Home Page</h1>
    </div>
  );
};

export default Home;
