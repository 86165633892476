import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css'; 
import AppRouter from './Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



function App() {
  return (
    <div className="App">
      <Router>
        <AppRouter /> {/* AppRouter now has access to router context */}
      </Router>
    </div>
  );
}

export default App;







